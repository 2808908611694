
.containerTab {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 5;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 8px;
    padding-right: 8px;
    height: 3em;
    background: linear-gradient(var(--graphite), var(--graphite)) padding-box,
              linear-gradient(to right, #09abfd, #00ffb380) border-box;
    border-radius: 8px;
    border: 1px solid transparent;
  }

.tabLogo{
    position: absolute;
    top: 16px;
    left: 32px;
    z-index: 5;
    width: 5em;
    transition: width 0.2s linear;
}

.tabLogo:hover{
    width: 5.1em;
}

@media screen and (max-width: 575px) {
    .tabLogo{
        width: 3em;
        top: 32px;
        left: 16px;
    }
    .tabLogo:hover{
        width: 3em;
    }
    .containerTab{
        gap: 8;
    }
  }

  @media screen and (max-width: 400px) {
    .tabLogo{
        width: 2.5em;
        top: 38px;
        left: 16px;
    }
    .tabLogo:hover{
        width: 2.6em;
    }
    .containerTab{
        gap: 2px;
        right: 8px;
    }
  }

.tabLink {
    text-decoration: none;
    padding-top: 10px;
    height: 30px;
    font-family: Nasalization;
    color: var(--silver);
    transition: color 0.4s linear;
}

.tabDivBtn {
    height: 30px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 4px;
    transition: background-color 0.4s linear, font-weight 0.4s linear;
}

.tabDivBtn:hover {
    background-color: var(--silver);
}

.tabDivBtn:hover .tabLink {
    color: var(--dark);
}


