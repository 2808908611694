:root {
  color-scheme: dark;
  --dark: #040404;
  --graphite: #252525;
  --iron: #A0A0A0;
  --silver: #f0f0f0;
  --re-blue: #09abfd;
}

body {
  margin: 0;
  height: 100svh;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--dark);
}

@font-face {
  font-family: 'Nasalization';
  src: url('./assets/fonts/nasalization-rg.otf') format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-VariableFont_slnt\,wght.ttf') format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
  font-size: 4em;
  font-family: 'Nasalization';
  /* color: var(--silver); */
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255), rgba(255, 255, 255, 0.539), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
h2{
  font-size: 2.5em;
  font-family: 'Nasalization';
  background: -webkit-linear-gradient(rgb(255, 255, 255), rgb(255, 255, 255), rgb(126, 126, 126), rgba(0, 0, 0, 0));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h3{
  font-size: 2em;
  font-family: 'Inter';
  font-weight: medium;
  color: var(--silver)
}
p{
  font-size: 2em;
  font-family: 'Inter';
  font-weight: medium;
  color: var(--iron)
}

@media screen and (max-width: 575px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 20px;
    font-weight: medium;
  }
  p {
    font-size: 20px;
    font-weight: medium;
  }

}


