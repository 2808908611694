.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100svh;
    flex-direction: column;
}

.squares {
    position: absolute;
    width: 800px; 
}

.logo {
    width: 500px;
}

@media screen and (max-width: 500px) {
    .logo {
        width: 300px;
    }
 }

 @media screen and (max-width: 800px) {
    .squares {
        width: 100%;
    }
 }



