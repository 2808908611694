.imgServices {
    width: 400px;
    height: 400px;
    border-radius: 16px; 
    opacity: 0.8;
}
.imgServicesWeb {
    width: 100%;
}
.descriptionAndImg{
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.description{
    width: 800px;
}


@media screen and (max-width: 860px) {
    .description{
        width: 90%;
        padding: 0 24px;
    }
  }

    @media screen and (min-width: 860px) {
    .imgServicesWeb {
        width: 860px;
        }
    }

    @media screen and (max-width: 420px) {
    .imgServicesWeb {
        width: 80%;
        }
    .imgServices {
        width: 80%;
        height: 80%;
        }
        
    }