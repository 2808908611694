.exp {
    opacity: 0.5;
    transition: opacity 0.2s linear;
    margin-block-end: 0;
}
.exp:hover {
    opacity: 1;
}

@media screen and (max-width: 575px) {
    .exp {
        font-size: 24px;
        opacity: 1;
    }
}